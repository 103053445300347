import axios, {AxiosError} from 'axios';

import {getUserToken} from '../helpers';
import {returnAPILink} from '../helpers/constants';

axios.interceptors.request.use(function(config) {
  const token = getUserToken();
  token && (config.headers.authorization = token);
  return config;
});

export const request = async (url: string) => {
  try {
    const result = await axios.get(`${returnAPILink()}/${url}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const toolRequest = async (url: string, input?: string, obj?: any) => {
  const result = {
    data: null as any,
    status: 0,
    errorData: null as {statusCode: number; message: string; error: string} | null,
  };

  try {
    const response = await axios.post(`${returnAPILink()}/${url}`, {source: input, ...(obj || {})});
    result.data = response.data;
  } catch (e) {
    //@ts-ignore
    if (e.response) {
      //@ts-ignore
      result.status = e.response.status || 0;
      //@ts-ignore
      result.errorData = e.response.data || '';
    }
  }
  return result;
};

export const toolHistoryRequest = async (toolName: string, page: number) => {
  try {
    const result = await axios.get(`${returnAPILink()}/history`, {
      params: {
        toolName,
        page,
        limit: 10,
      },
    });
    return result.data;
  } catch (e) {
    //@ts-ignore
    return e.response.status;
  }
};
export const toolHistoryRequestById = async (id: string) => {
  try {
    const result = await axios.get(`${returnAPILink()}/history/${id}`);
    return result.data;
  } catch (e) {
    //@ts-ignore
    return e.response.status;
  }
};

export const getVoices = async (lang: string) => {
  try {
    const result = await axios(
      `https://content-texttospeech.googleapis.com/v1/voices?languageCode=${lang}&key=AIzaSyBharYuJlXffjVzuZsPN5zvVvdwq0W0M9M`,
    );
    return result?.data?.voices?.filter((item: any) => item.name !== 'en-US-Journey-F' && item.name !== 'en-US-Journey-O')?.map((item: any) => {
      return {label: item.name, value: item.name, gender: item.ssmlGender};
    });
  } catch (error) {
    return error;
  }
};
