import React from 'react';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import ToolsHostoryWrapper from '../../../components/tools-components/history';
import {toolsPath} from '../../../helpers/constants';

const ToolsHistory = () => {
  const filter =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.hash &&
    global.window.location.hash.replace('#', '');
  return (
    <Layout>
      <PageHero
        title="History of tasks"
        subtitle="Find all your previously run tasks using the Hexomatic website tools."
        id='seo_home_area_tools'
      />
      <Section>
        {
          //@ts-ignore
          <ToolsHostoryWrapper filter={toolsPath[filter] || ''} />
        }
      </Section>
    </Layout>
  );
};
export default ToolsHistory;
